<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addImages'})">
      {{ $t('auth.images') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="4" class="mb-3">
            <input-form placeholder="العنوان" label="العنوان" v-model="imageData.gallery.title" name="title" validate="required"></input-form>
          </b-col>
          <b-col md="9" class="mb-3">
            <textarea-form  placeholder="المحتوى" v-model="imageData.gallery.content" label="التفاصيل"  name="description" validate="required"></textarea-form>
          </b-col>
          <b-col md="9" class="mb-3">
            <label class="text-black font-size-18">تاريخ النشر</label>
            <flat-picker  v-model="imageData.gallery.publish_date" class="form-control form-date"  validate="required" name="publishDate" :config="{nowDate: 'today'}" placeholder="تاريخ النشر"></flat-picker>
          </b-col>
          <b-col md="9" class="my-3">
          <cropper-images
              label="رفع الصورة الرئيسية"
              nameOfImage="image.jpg"
              @cropper-save="saveMasterImage"
              :progressLoading="loadingLogo"
              :showProgress="false"
              :multi="false"
              :imageUrl="imageData.gallery.featured_image"
          />
          </b-col>
          <b-col md="9" class="my-3">
            <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="text-primary">
                  <i class="las la-question-circle"></i>
                  الصورة الخاصة بالمعرض
                </h5>
                <span class="cursor-pointer text-warning font-size-16" v-if="!showFaqInput" @click="showFaqInput = true"> إضافة صورة +</span>
                <span class="cursor-pointer text-warning font-size-16" v-else @click="showFaqInput = false"> إلغاء الصورة</span>
              </div>
            </template>
            <ValidationObserver v-slot="{ handleSubmit }" ref="gallery_media" v-if="showFaqInput">
              <b-form @submit.prevent="handleSubmit(addFaq)">
                <b-row>
                  <b-col md="12" >
                    <input-form placeholder="العنوان" label="العنوان" v-model="gallery.title" name="العنوان" validate="required"></input-form>
                  </b-col>
                  <b-col md="12">
                    <textarea-form placeholder="التفاصيل" label="التفاصيل" v-model="gallery.description" name="التفاصيل" validate="required"></textarea-form>
                  </b-col>
                  <b-col md="12" class="my-3">
                    <cropper-images
                        label="رفع الصورة"
                        nameOfImage="image.jpg"
                        @cropper-save="saveAnotherImage"
                        :progressLoading="loadingLogo"
                        :showProgress="false"
                        :multi="false"
                        :imageUrl="gallery.url"
                    />
                  </b-col>
                  <b-col class="justify-content-end d-flex my-2">
                    <b-button variant="warning"  type="submit" class="text-white w-20"> حفظ الصورة </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
            <b-row>
              <b-col md="12" class="mb-3 cursor-pointer" v-if="imageData.gallery_media.length == 0">
                <p class="mb-0 font-size-18 mt-2">لا يوجد صورة</p>
              </b-col>
              <b-col md="12" v-else class="d-flex justify-content-between py-3 mb-4" v-for="(faq, key) in imageData.gallery_media" :key="key">
                <div class="d-flex flex-column flex-grow-1 px-2">
                  <p class="font-size-18">{{ faq.title }}</p>
                  <p class="font-size-16">{{ faq.description }}</p>
                  <div class="h-250px" :style="{
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat',
                  'background-image': 'url(' + faq.url + ')'
                  }">
                  </div>
                </div>
                <span  @click="removeFaq(key)" class="cursor-pointer">
                        <i class="ri-delete-bin-line text-danger font-size-20" />
                     </span>
              </b-col>
            </b-row>
          </b-card>
          </b-col>
      </b-row>
        <b-row>
          <b-col md="9" class="d-flex justify-content-center align-items-center">
            <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.gallery') }}</b-button>
            <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
          </b-col>
        </b-row>
<!--                <div class="d-flex align-items-center justify-content-center ">-->
<!--                  <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.events') }}</b-button>-->
<!--                  <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>-->
<!--                </div>-->
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventsServices from '../services/images'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      gallery: { title: '', description: '', url: '' },
      showFaqInput: false,
      loadingSubmit: false,
      loadingLogo: 0,
      loadingGallery: 0,
      removeLoadingUi: false,
      imageData: {
        gallery: {
          featured_image: '',
          title: '',
          content: '',
          publish_date: ''
        },
        gallery_media: []
      },
      img: ''
    }
  },
  methods: {
    addFaq () {
      this.imageData.gallery_media.push(this.gallery)
      this.$refs.gallery_media.reset()
      this.gallery = { title: '', description: '', url: '' }
    },
    removeFaq (key) {
      this.imageData.gallery_media.splice(key, 1)
    },
    getImageData () {
      eventsServices.getImage(this.$route.params.id).then(res => {
        this.imageData = res.data
      })
    },
    saveMasterImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options).then(res => {
        this.imageData.gallery.featured_image = res.data.url
        this.showSuccessUploadFile()
      }).catch(err => {
        console.log(err)
      })
    },
    saveAnotherImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options).then(res => {
        this.gallery.url = res.data.url
        this.showSuccessUploadFile()
      }).catch(err => {
        console.log(err)
      })
    },
    // saveGalleryImage (data) {
    //   this.removeLoadingUi = false
    //   const formData = new FormData()
    //   formData.append('file', data.image)
    //   formData.append('name', data.imageInfo.name)
    //   const options = {
    //     onUploadProgress: (progressEvent) => {
    //       const { loaded, total } = progressEvent
    //       const percent = Math.floor((loaded * 100) / total)
    //       this.loadingGallery = percent
    //     }
    //   }
    //   this.commonUploadImages(formData, options).then(res => {
    //     this.imageData.gallery_media.push(res.data)
    //     this.showSuccessUploadFile()
    //     this.removeLoadingUi = true
    //   })
    // },
    onSubmit () {
      if (this.$route.name === 'editImage') {
        this.editImage()
      } else {
        this.loadingSubmit = true
        eventsServices.addImage(this.imageData).then(res => {
          core.showSnackbar('success', 'تم اضافة ')
          this.loadingSubmit = false
          this.$router.push({ name: 'images' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    },
    editImage () {
      eventsServices.editImage(this.$route.params.id, this.imageData).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'images' })
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getImageData()
    }
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
